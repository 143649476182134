<template>
  <div class="news_page">
    <div class="news_top">
      <div
        class="tab"
        @click="handleActive('news')"
        :class="{ active: this.active === 'news' }"
      >
        动态资讯
      </div>
      <div
        class="tab"
        @click="handleActive('guid')"
        :class="{ active: this.active === 'guid' }"
      >
        政策导向
      </div>
      <div
        class="tab"
        @click="handleActive('activity')"
        :class="{ active: this.active === 'activity' }"
      >
        培训活动
      </div>
    </div>
    <div class="news_list">
      <div v-if="this.newList">
        <div
          class="news_block tab"
          v-for="(item, index) in newList"
          :key="index"
          @click="toReport(item)"
        >
          <div class="left">
            <img :src="constant.URL + item.coverImage" alt="" />
          </div>
          <div class="right">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="text">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center" v-else>暂无资讯</div>
    </div>
    <!-- <el-pagination
      v-if="params.total"
      style="margin-top: 20px"
      background
      layout="prev, pager, next"
      :total="params.total"
    >
    </el-pagination> -->
    <comPagination
      :params="params"
      @changePage="changePage"
      v-if="this.active == 'news'"
    ></comPagination>
    <comPagination1
      :params="params1"
      @changePage="changePage"
      v-else-if="this.active == 'guid'"
    ></comPagination1>
    <comPagination2
      :params="params2"
      @changePage="changePage"
      v-else-if="this.active == 'activity'"
    ></comPagination2>
  </div>
</template>

<script>
import comPagination from "@/components/admin/pagination.vue";
import comPagination1 from "@/components/admin/pagination.vue";
import comPagination2 from "@/components/admin/pagination.vue";
export default {
  name: "News",
  components: { comPagination, comPagination1, comPagination2 },
  data() {
    return {
      active: "news",
      newList: [],
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
      params1: {
        page: 0,
        size: 10,
        total: 0,
      },
      params2: {
        page: 0,
        size: 10,
        total: 0,
      },
    };
  },
  created() {
    this.active = this.$route.query.tag || "news";
    this.getNews();
  },
  methods: {
    tab(val) {
      this.isShow = !this.isShow;
      console.log(val);
      this.$refs.mySwiper.slideTo(0, 1000, false);
    },
    toReport(item) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          id: item.id,
        },
      });
    },
    getNews() {
      let data = {
        type: this.active == "news" ? 1 : this.active == "guid" ? 2 : 3,
      };
      let a =
        this.active == "news"
          ? this.params
          : this.active == "guid"
          ? this.params1
          : this.params2;
      this.api.home.newList(data, a).then((res) => {
        console.log("分页", res);
        if (res.flag) {
          this.newList = res.data.list;
          if (this.active == "news") {
            this.params.total = res.data.total;
          } else if (this.active == "guid") {
            this.params1.total = res.data.total;
          } else if (this.active == "activity") {
            this.params2.total = res.data.total;
          }
        }
      });
    },
    handleActive(val) {
      this.active = val;
      console.log("this.active", this.active);
      this.getNews();
    },
    //分页 todo 待测试
    changePage(val) {
      this.params.page = val;
      this.getNews();
    },
  },
};
</script>

<style lang="scss" scoped>
.news_page {
  width: 1200px;
  margin: 100px auto;
  .news_top {
    background: #cc1a30;
    height: 180px;
    margin-bottom: 180px;
    box-sizing: border-box;
    @include flex(row, space-around, center);
    div {
      font-size: 46px;
      line-height: 46px;
      opacity: 0.41;
      color: #fff;
      font-weight: 700;
      position: relative;
      top: 20px;
    }
    .active {
      opacity: 1;
    }
    .active::before {
      content: "";
      height: 4px;
      width: 140px;
      background: #fff;
      display: block;
      position: absolute;
      left: 50%;
      top: -36px;
      margin-left: -70px;
    }
    .active::after {
      content: "";
      height: 64px;
      width: 168px;
      @include bg("../../assets/img/home/arr.png");
      display: block;
      position: absolute;
      left: 50%;
      top: 90px;
      margin-left: -84px;
    }
  }
  .news_list {
    .news_block {
      @include flex(row, flex-start, flex-start);
      padding: 20px;
      border-bottom: 1px solid #a9a8a8;
      margin-bottom: 10px;
      margin-top: 10px;
      .left {
        width: 496px;
        height: 204px;
        box-sizing: border-box;
        text-align:center;
        img {
          height: 100%;
          width: auto;
          
        }
      }
      .right {
        width: 700px;
        margin-left: 20px;
        color: #228ded;
        @include flex(column, flex-start, flex-start);
        .title {
          line-height: 30px;
          font-weight: bold;
          font-size: 28px;
          margin-bottom: 16px;
          padding-top: 16px;
          text-align: left;
        }
        .text {
          line-height: 34px;
          height: 136px;
          overflow: hidden;
          text-align: left;
        }
      }
    }
  }
  .report {
    .title {
      font-family: AlibabaPuHuiTiB;
      font-size: 36px;
      line-height: 40px;
      letter-spacing: 4px;
      color: #ab1f00;
      font-weight: 700;
      margin-bottom: 50px;
      text-align: center;
    }
    .title_block {
      @include flex();
      font-family: AlibabaPuHuiTiM;
      font-size: 24px;
      line-height: 48px;
      letter-spacing: 1px;
      color: #ab1f00;
      text-align: center;
      margin-bottom: 70px;

      .time {
        margin: 0 30px;
      }
      .record {
        height: 0.5rem;
        background: #d58f7f url(../../assets/img/home/eye.png) 25px center
          no-repeat;
        background-size: 44px 25px;
        color: #fff;
        border-radius: 25px;
        text-align: left;
        line-height: 48px;
        padding-left: 80px;
        padding-right: 25px;
      }
    }
  }
}
</style>
<style lang="scss">
.news_page {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #cc1a30 !important;
    color: #fff !important;
  }
  .el-pagination.is-background .el-pager li {
    background: #fff !important;
    border: solid 1px #ddd;
    color: #cc1a30 !important;
    border-radius: 5px !important;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    background-color: #eee !important;
  }
}
</style>
